<template> 
    <v-layout justify-center class="pt-9" fill-height>   
        <mbs-page-spinner v-if="!DayShift" />   
        <mbs-epson-display-temp1 v-else
            :show="true"
            :loading="inputLoading"
            :print_request="DAY_SHIFT_PRINT_REQUEST"/> 
        
    </v-layout> 
</template>

<script>
    import DATA from "../../../plugins/DATA"
    import {mapState } from "vuex"
    let PAGE_NAME  = 'DAY-SHIFT-TEMPLATE-1'
    export default {
        props:['data','report_type','sales_type','closing_cash'], 
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                inputLoading:true,
                DAY_SHIFT_PRINT_REQUEST:null,
            } 
        }, 
        mounted(){ 
            this.EPSON_DAY_SHIFT_REQUEST({
                report_type:this.report_type,
                day_shift:this.DayShift,
            }) 
        },
        computed:{
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],  
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            }), 
            DataInvoicePayments(){
                let Payments = this.Payments
                let JoinedPayments = this.JoinedPayments 
                return JoinedPayments?JoinedPayments:Payments
            },
            DataSaleOrders(){
                let SaleOrders = this.SaleOrders
                let JoinedSaleOrders = this.JoinedSaleOrders 
                return JoinedSaleOrders?JoinedSaleOrders:SaleOrders
            },
            AllLocalSaleOrdersData(){  
                const LocalSaleOrders = this.LocalSaleOrders
                const JoinedLocalSaleOrders = this.JoinedLocalSaleOrders
                let items = JoinedLocalSaleOrders?JoinedLocalSaleOrders:LocalSaleOrders  
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
                return list
            }, 
            BothSaleOrders(){
                let orders = this.DataSaleOrders
                let localOrders = this.AllLocalSaleOrdersData
                if(!orders && !localOrders){return null}
                if(orders && !localOrders){return orders}
                if(!orders && localOrders){return localOrders}

                let both = [...orders]
                localOrders.forEach(element => {
                    let item = orders.find(item=>{
                        return item.key == element.key
                    })
                    if (!item) {
                        both.push(element)
                    } 
                });
                let list = [...both]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            },
            ShowShortagesSettings(){
                let settings = this.CompanySettings
                let show_shortages = settings?settings.show_shortages:false 
                return show_shortages
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            DayShift(){ 
                let data = this.data 
                return  data
            },
            Company(){ 
                let item = this.DayShift
                return item?item.company:null
            }, 
            Cashups(){ 
                let item = this.DayShift 
                return item?item.cashups:null
            }, 
            CashupSalesOtherOptionPayments(){ 
                let cashups = this.Cashups 
                if(!cashups){return null}
                let options = []
                for (const cashup_key in cashups) {
                    const element = cashups[cashup_key]; 
                    let other_option_payments = element.sales_other_option_payments
                    if (other_option_payments) {
                        for (const key in other_option_payments) {
                            const other_option = other_option_payments[key]; 
                            let index = options.findIndex(option=>{
                                return option.payment_option_code  == key
                            }) 
                            if (index == -1) {
                                let payment_method = this.$store.getters.getPaymentMethodByCode(key)
                                options.push({
                                    ...other_option,
                                    payment_method:payment_method,
                                    payment_method_name:payment_method?payment_method.name:"---", 
                                })
                            } else {
                                options[index].amount = options[index].amount+this.toNumber(other_option.amount)
                            }  
                        }
                    }  
                } 
                return this.MBS.actions.SIZE(options)>0?options:null
            }, 
            Joined_Cashups(){ 
                let item = this.DayShift  
                let cashups = item?item.joined_cashups:null   
                return cashups
            }, 
            TotalCashups(){ 
                let items = this.Joined_Cashups 
                let total = this.MBS.actions.CALCULATE_TOTAL_CASHUPS(items)  
                return total
            }, 
            ClosedCashups(){ 
                let items = this.Joined_Cashups 
                if(!items){return null} 
                let filtered = items.filter(cashup=>{
                    return cashup.closed
                })
                return filtered
            }, 
            UnClosedCashups(){ 
                let items = this.Joined_Cashups 
                if(!items){return null} 
                let filtered = items.filter(cashup=>{
                    return !cashup.closed
                })
                return filtered
            }, 
            
            AllShortages(){ 
                let items = this.Joined_Cashups 
                if(!items){return null}
                let shortage = 0
                items.forEach(cashup => {
                    shortage = shortage + this.SHORTAGE(cashup)
                });
                return shortage
            }, 
            AllOverages(){ 
                let items = this.Joined_Cashups 
                if(!items){return null}
                let overage = 0
                items.forEach(cashup => {
                    overage = overage + this.OVERAGE(cashup)
                });
                return overage
            }, 

            
             
            DayShiftSaleOrders(){  
                let sales = this.BothSaleOrders
                let DayShift = this.DayShift
                let day_shift_key = DayShift?DayShift.key:null 
                if(!day_shift_key||!sales){return null}
                let filtered_items = sales.filter(item=>{
                    return item.day_shift_key == day_shift_key
                })   
                return filtered_items  
            }, 
            DayShiftSaleOrdersItems(){
                let sales = this.DayShiftSaleOrders  
                let items = this.ORDER_ITEMS(sales)  
                return items
            },
            RetailDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.RETAIL_ITEMS(items)
                return retail_items 
            },
            WholesaleDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.WHOLESALE_ITEMS(items)
                return retail_items 
            },
            CashDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.CASH_ITEMS(items)
                return retail_items 
            },
            OtherOptionsDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.OTHER_OPTIONS_ITEMS(items) 
                return retail_items 
            },
            OnAccountDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.ON_ACCOUNT_ITEMS(items)
                return retail_items 
            },
            
            CashupOrders(){
                let orders = this.DayShiftSaleOrders
                return cashup_key =>{
                    if(!orders){return null}
                    return orders.filter(order=>{
                        return order.cashup_key == cashup_key
                    })
                }
            },
            CashupOrdersItems(){
                return (cashup_key)=>{
                    let sales = this.CashupOrders(cashup_key)
                    let items = this.ORDER_ITEMS(sales) 
                    return items 
                }
            },
            RetailCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.RETAIL_ITEMS(items)
                    return retail_items  
                }
            },
            WholesaleCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.WHOLESALE_ITEMS(items)
                    return retail_items  
                }
            },
            CashCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.CASH_ITEMS(items) 
                    return retail_items  
                }
            },
            OtherOptionsCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.OTHER_OPTIONS_ITEMS(items) 
                    return retail_items  
                }
            },
            OnAccountCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.ON_ACCOUNT_ITEMS(items)
                    return retail_items  
                }
            }, 
            TotalClosingCash(){
                let cashups = this.Joined_Cashups
                if(!cashups){return null}
                let total = 0
                cashups.forEach(element => {
                    total = total+this.toNumber(element.closing_cash)
                });
                return total
            }, 
            ThisInvoicePayments(){
                let payments = this.DataInvoicePayments
                let DayShift = this.DayShift
                let day_shift_key = DayShift?DayShift.key:null
                if(!payments||!day_shift_key){return null}
                let filled_items = payments.filter(item=>{
                    return item.day_shift_key == day_shift_key
                }) 
                return filled_items
            },
            CashInvoicePayments(){
                let payments = this.ThisInvoicePayments 
                if(!payments){return null}
                let filled_items = payments.filter(item=>{
                    return !item.payment_option_code
                }) 
                return filled_items
            },
            OtherInvoicePayments(){  
                let payments = this.ThisInvoicePayments 
                if(!payments){return null}
                let filled_items = payments.filter(item=>{
                    return item.payment_option_code
                })  
                return filled_items
            },
            TotalInvoicePayments(){
                try { 
                    let items = this.ThisInvoicePayments
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });  
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalInvoicePayments',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            SalesOtherOptionPayments(){
                //Cashup Data
                let TotalCashups = this.TotalCashups
                let sales_other_option_payments = TotalCashups?TotalCashups.sales_other_option_payments:null
                let sales_total_other_option_payments = TotalCashups?TotalCashups.sales_total_other_option_payments:0 
                 
                //Other Data
                let items = this.OtherOptionsDayShiftItems   
                let total_item_options = 0
                let options = []
                if (items) {
                    items.forEach(element => {
                        let payment_option_code = element.payment_option_code
                        let sold_price = this.toNumber(element.sold_price)
                        let quantity = this.toNumber(element.quantity)
                        let discount = this.toNumber(element.discount)
                        let price = (sold_price-discount) *quantity

                        let index = options.findIndex(option=>{
                            return option.payment_option_code  == payment_option_code
                        }) 
                        if (payment_option_code) {
                            if (index == -1) {
                                let payment_method = this.$store.getters.getPaymentMethodByCode(payment_option_code)
                                options.push({
                                    payment_method:payment_method,
                                    payment_method_name:payment_method?payment_method.name:"---",
                                    payment_option_code:payment_option_code,
                                    amount:price
                                })
                            } else {
                                options[index].amount = options[index].amount+price
                            }
                            total_item_options = total_item_options+price
                        } 
                    });   
                }
                return sales_total_other_option_payments>total_item_options?{
                    options:sales_other_option_payments,
                    total:sales_total_other_option_payments,
                }:{
                    options:options,
                    total:total_item_options
                }
            },  
            InvoiceOtherOptionPayments(){
                //From Cashup Data
                let TotalCashups = this.TotalCashups
                let invoices_other_option_payments = TotalCashups?TotalCashups.invoices_other_option_payments:null
                let invoices_total_other_option_payments = TotalCashups?TotalCashups.invoices_total_other_option_payments:0 
                 
                //Offline
                let items = this.OtherInvoicePayments   
                let total_item_options = 0
                let options = []
                if (items) {
                    items.forEach(element => {
                        let payment_option_code = element.payment_option_code
                        let index = options.findIndex(option=>{
                            return option.payment_option_code  == payment_option_code
                        }) 
                        if (payment_option_code) {
                            if (index == -1) {
                                let payment_method = this.$store.getters.getPaymentMethodByCode(payment_option_code)
                                options.push({
                                    payment_method:payment_method,
                                    payment_method_name:payment_method?payment_method.name:"---",
                                    payment_option_code:payment_option_code,
                                    amount:this.toNumber(element.amount)
                                })
                            } else {
                                options[index].amount = options[index].amount+this.toNumber(element.amount) 
                            }
                            total_item_options = total_item_options+this.toNumber(element.amount)
                        } 
                    });   
                }
                return invoices_total_other_option_payments>total_item_options?{
                    options:invoices_other_option_payments,
                    total:invoices_total_other_option_payments,
                }:{
                    options:options,
                    total:total_item_options
                }
            },  
            AllOtherOptionPayments(){
                //From Cashup Data
                let SalesOtherOptionPayments = this.SalesOtherOptionPayments
                let InvoiceOtherOptionPayments = this.InvoiceOtherOptionPayments
                let options = [] 

                if (SalesOtherOptionPayments.options) {
                    options = [...SalesOtherOptionPayments.options]
                }if (InvoiceOtherOptionPayments.options) {
                    InvoiceOtherOptionPayments.options.forEach(element => {
                        let exist = options.findIndex(item=>item.payment_option_code==element.payment_option_code)
                        if (exist != -1) {
                            options[exist].amount = options[exist].amount+element.amount
                        } else {
                            options.push(element) 
                        } 
                    }); 
                } 

                console.log(options,'options....');
                return {
                    options:options,
                    total:this.toNumber(SalesOtherOptionPayments.total)+this.toNumber(InvoiceOtherOptionPayments.total)
                }
            },  
        },
        methods:{
            async EPSON_DAY_SHIFT_REQUEST(payload){ 
                try {   
                    let day_shift = this.DayShift 
                    this.inputLoading = true
                    // let print_order = await this.MBS.actions.EPSON_DAY_SHIFT_REQUEST(payload) 
                    let print_order = await this.EPSON_DAY_SHIFT_REQUEST_V1(payload) 
                    this.DAY_SHIFT_PRINT_REQUEST = print_order?print_order.data:null
                    this.inputLoading = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EPSON_DAY_SHIFT_REQUEST',
                        page:PAGE_NAME, 
                    }) 
                    return 
                }   
            },  
            async EPSON_DAY_SHIFT_REQUEST_V1(payload){ 
                return new Promise((resolve,reject)=>{
                    let response = {
                        from:"EPSON_DAY_SHIFT_REQUEST_V1",
                        page:PAGE_NAME,
                        payload:payload
                    } 
                    try {   
                        let day_shift = payload?payload.day_shift:null
                        if(!day_shift){return null}
                        let cashup_option = day_shift?day_shift.cashup_option:null
                        let cashups = day_shift?day_shift.joined_cashups:null
                        let running_cashups = cashups?cashups.filter(c=>!c.closed):null
                        let running_cashups_size = this.MBS.actions.SIZE(running_cashups)
                        let isRunning = running_cashups_size?true:false
                        let isClosed = day_shift?day_shift.closed:null

                        //USED TILLS
                        let used_floating_cash = 0
                        let used_till_keys = []
                        if (cashups) {
                            cashups.forEach(element => {
                                let index = used_till_keys.indexOf(element.till_key)
                                if (index==-1) {
                                    used_floating_cash = used_floating_cash+this.toNumber(element.floating_cash)
                                    used_till_keys.push(element.till_key)
                                }
                            });
                        }

                        console.log(day_shift,'day_shift........................');
                        console.log(cashups,'cashups........................');
                        let tills = day_shift?day_shift.joined_tills?day_shift.joined_tills:day_shift.tills:null
                        let total_cashups = this.MBS.actions.CALCULATE_TOTAL_CASHUPS(cashups,day_shift)  
                        console.log(total_cashups,'total cashups..............................');
                        let report_type = payload?payload.report_type:null
                        let printer = payload?payload.printer?payload.printer :{}:{}

                        const default_paper_size = 32
                        const default_printer_name = "POS80"
                        let paper_size = 0
                        let printer_name = ""
                        let space_value = '.'   
                        let print_order = {}

                        //SETTINGS
                        let settings = this.MBS.actions.CompanySettings
                        let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                        let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                        let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                        let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                        let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                        let receipt_show_till = settings?settings.receipt_show_till:null
                        let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                        let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                        let receipt_show_vat = settings?settings.receipt_show_vat:null

                        //PRINTER INFO
                        paper_size = printer.paper_size?printer.paper_size:default_paper_size
                        printer_name = printer.printer_name?printer.printer_name:default_printer_name
                        print_order.paper_size = paper_size
                        print_order.printer_name = printer_name 
                        print_order.lines = [] 
            
                    
                        //TOP HEADER
                        if (true) {
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'top_header',
                                text:"*** DAY SHIFT ***"
                            })
                        } 

                        //COMPANY INFO
                        const company = day_shift?.company
                        const outlet = day_shift?.outlet 
                        const c_company_name = company?.company_name
                        const c_phone_number_cell = company?.company_phone_number
                        const c_phone_number_tell = company?.company_phone_number2
                        const c_post_address = company?.company_post_address
                        const c_email_address = company?.company_email_address
                        const c_tpin = company?.company_tpin 
                        const c_location_address = company?.company_location_address 
                        
                        const receipt_company_name = outlet?.receipt_company_name
                        const receipt_phone_number_cell = outlet?.receipt_phone_number_cell
                        const receipt_phone_number_tell = outlet?.receipt_phone_number_tell
                        const receipt_post_address = outlet?.receipt_post_address
                        const receipt_email_address = outlet?.receipt_email_address
                        const receipt_tpin = outlet?.receipt_tpin
                        const receipt_address = outlet?.receipt_address
                        
                        const company_name = receipt_company_name?receipt_company_name:c_company_name
                        const company_phone_number_cell = receipt_phone_number_cell?receipt_phone_number_cell:c_phone_number_cell
                        const company_phone_number_tell = receipt_phone_number_tell?receipt_phone_number_tell:c_phone_number_tell
                        const company_post_address = receipt_post_address?receipt_post_address:c_post_address
                        const company_email_address = receipt_email_address?receipt_email_address:c_email_address
                        const company_tpin = receipt_tpin?receipt_tpin:c_tpin
                        const company_address =  receipt_address?receipt_address:c_location_address
                        
                        if (company) { 
                            print_order.lines.push({
                                align:"center",
                                bold:true, 
                                value:'company_name',
                                text:company_name 
                            })
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'company_post_address',
                                text:"Address: "+company_post_address
                            })
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'company_email_address',
                                text:"Email: "+company_email_address
                            }) 
                            let phone_details = "(Cell): "+company_phone_number_cell
                            if (company_phone_number_tell) {
                                phone_details = phone_details +" (Tell): "+company_phone_number_tell 
                                if (paper_size<43) {
                                    print_order.lines.push({
                                        align:"center",
                                        bold:false,
                                        value:'company_phone_number_cell',
                                        text: "(Cell): "+company_phone_number_cell
                                    })
                                    print_order.lines.push({
                                        align:"center",
                                        bold:false,
                                        value:'company_phone_number_tell',
                                        text:"(Tell): "+company_phone_number_tell 
                                    })
                                }
                            }
                            if (paper_size>42) { 
                                print_order.lines.push({
                                    align:"center",
                                    bold:false,
                                    value:'company_phone_number_cell',
                                    text:phone_details
                                })  
                            }
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'company_tpin',
                                text:"TPIN: "+company_tpin 
                            })   
                        }
            
                        //DOWN HEADER
                        if (report_type) {
                            print_order.lines.push({text:" "})
                            print_order.lines.push({value:"line"})
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'top_header',
                                text:"**** "+report_type+" ****"
                            })
                            print_order.lines.push({value:"line"})
                            print_order.lines.push({text:" "})
                        }

                        //DAY SHIFT DETAILS
                        if (true) {
                            // print_order.lines.push({ text:' '})  
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"DAY:", 
                                    last:""+day_shift.operating_date_,  
                                    size:paper_size
                                }) 
                            }) 
                            
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"OPEN BY:", 
                                    last:""+day_shift.user_name,  
                                    size:paper_size
                                })
                
                            })
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"OPEN AT:", 
                                    last:""+day_shift.local_created_at_,  
                                    size:paper_size
                                })
                
                            })

                            if (report_type=="X-READ") {
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"PRINTED AT:", 
                                        last:""+day_shift.local_close_at_,  
                                        size:paper_size
                                    })
                    
                                }) 
                            }else if (isClosed) {
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"CLOSE AT:", 
                                        last:""+day_shift.local_close_at_,  
                                        size:paper_size
                                    })
                    
                                }) 
                            }else{
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"CLOSE AT:", 
                                        last:" [Running...]",  
                                        size:paper_size
                                    })
                    
                                }) 
                            }
                            if (tills) { 
                                let till_names = '' 
                                tills.forEach((till,index) => {
                                    if (index == 0) {
                                        till_names = till_names+till.name 
                                    }else{
                                        till_names = till_names+", "+till.name 
                                    }
                                });
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"TILLS:", 
                                        last:"["+till_names+"]",  
                                        size:paper_size
                                    })
                    
                                })  
                            }
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"TOTAL FLOATING CASH:", 
                                    last:""+day_shift.total_floating_cash_,  
                                    size:paper_size
                                })
                
                            }) 
                        }
                        
                        //CASHUPS
                        if (cashups) {
                            print_order.lines.push({ text:" "})
                            print_order.lines.push({
                                align:"center",
                                bold:false, 
                                text:"CASHUPS(S) / REGISTER(S)"
                            })

                            cashups.forEach(cashup => {
                                let cashup_closed = cashup.closed 
                                let opening_cash = this.MBS.actions.toNumber(cashup.opening_cash )
                                let expected_opening_cash = cashup_option =='submit_cash_after_every_cashup'? 
                                    this.MBS.actions.toNumber(cashup.floating_cash ):
                                    this.MBS.actions.toNumber(cashup.expected_opening_cash )
                                let isOpenShortage = opening_cash!=expected_opening_cash?true:false
                                let opening_shortage = opening_cash-expected_opening_cash 
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"TELLER: ", 
                                        last:" "+cashup.teller_name,  
                                        size:paper_size,
                                        space_value:space_value
                                    }) 
                                }) 
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"TILL: ", 
                                        last:" "+cashup.till_name,  
                                        size:paper_size,
                                        space_value:space_value
                                    }) 
                                }) 
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"OPEN AT: ", 
                                        last:" "+day_shift.local_created_at_,  
                                        size:paper_size,
                                        space_value:space_value
                                    }) 
                                })  
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"CLOSE AT: ", 
                                        last:" "+(cashup_closed?cashup.close_date_time_:'['+cashup.close_date_time_+']'),  
                                        size:paper_size,
                                        space_value:space_value
                                    }) 
                                }) 
                                
                                
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"OPENING BALANCE: ", 
                                        last:" "+cashup.opening_cash_,  
                                        size:paper_size,
                                        space_value:space_value
                                    }) 
                                }) 
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"CASH SALES: ", 
                                        last:" "+this.MBS.actions.money(cashup.sales_cash_payments),  
                                        size:paper_size,
                                        space_value:space_value
                                    }) 
                                })  
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"OTHER SALES: ", 
                                        last:" "+this.MBS.actions.money(cashup.sales_total_other_option_payments),  
                                        size:paper_size,
                                        space_value:space_value
                                    }) 
                                }) 
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"ON ACCOUNT: ", 
                                        last:" "+this.MBS.actions.money(cashup.sales_on_account),  
                                        size:paper_size,
                                        space_value:space_value
                                    }) 
                                }) 

                                let total_invoice = this.MBS.actions.toNumber(cashup.invoices_cash_payments)
                                    +this.MBS.actions.toNumber(cashup.invoices_total_other_option_payments)
                                
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"PAID INVOICES: ", 
                                        last:" "+this.MBS.actions.money(total_invoice),  
                                        size:paper_size,
                                        space_value:space_value
                                    }) 
                                })
                                
                                if (!cashup.closed) {
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:this.MBS.actions.EPSON_1LINE({
                                            first:"CURRENT SALES: ", 
                                            last:" "+cashup.day_shift_total_sales_?cashup.day_shift_total_sales_:"",  
                                            size:paper_size,
                                            space_value:space_value
                                        }) 
                                    })
                                }else {
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:this.MBS.actions.EPSON_1LINE({
                                            first:"TOTAL SALES: ", 
                                            last:" "+this.MBS.actions.money(cashup.cashup_total_sales),  
                                            size:paper_size,
                                            space_value:space_value
                                        }) 
                                    }) 
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:this.MBS.actions.EPSON_1LINE({
                                            first:"CLOSING BALANCE: ", 
                                            last:" "+cashup.closing_cash_,  
                                            size:paper_size,
                                            space_value:space_value
                                        }) 
                                    })  
                                }
                                if (isOpenShortage) {
                                    if (opening_cash>expected_opening_cash) {
                                        print_order.lines.push({
                                            align:"left",
                                            bold:true,
                                            value:"",
                                            text:this.MBS.actions.EPSON_1LINE({
                                                first:"OPEN [OVER..]: ", 
                                                last:" "+this.MBS.actions.money(opening_shortage),  
                                                size:paper_size,
                                                space_value:space_value
                                            }) 
                                        }) 
                                    } else {
                                        print_order.lines.push({
                                            align:"left",
                                            bold:true,
                                            value:"",
                                            text:this.MBS.actions.EPSON_1LINE({
                                                first:"OPEN [SHORT..]: ", 
                                                last:" ("+this.MBS.actions.money(opening_shortage*-1)+")",  
                                                size:paper_size,
                                                space_value:space_value
                                            }) 
                                        }) 
                                    } 
                                }

                                if (cashup.shortage) {
                                    print_order.lines.push({
                                        align:"left",
                                        bold:true,
                                        value:"",
                                        text:this.MBS.actions.EPSON_1LINE({
                                            first:cashup.isShortage?"CLOSE [SHORT..]: ":"CLOSE [OVER..]: ", 
                                            last:cashup.isShortage?cashup.shortage_:cashup.shortage_,  
                                            size:paper_size,
                                            space_value:space_value
                                        }) 
                                    })   
                                }
                                print_order.lines.push({ text:" "})
                            });
                            
                            
                        }
                        
                        //PAYMENT OPTIONS
                        if (total_cashups) {
                            print_order.lines.push({ text:" "})
                            print_order.lines.push({ value:"line"})
                            print_order.lines.push({
                                align:"center",
                                bold:false, 
                                text:"PAYMENT(S)"
                            })

                            total_cashups.sales_other_option_payments.forEach(payment => { 
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:payment.payment_method_name, 
                                        last:this.MBS.actions.money(payment.amount),  
                                        size:paper_size
                                    }) 
                                }) 
                                
                            });
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"TOTAL:", 
                                    last:this.MBS.actions.money(
                                        this.toNumber(total_cashups.sales_total_other_option_payments)+
                                        this.toNumber(total_cashups.invoices_total_other_option_payments)),  
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({ text:" "})
                            
                            
                        }
                        
                        //SHORTAGES
                        if (total_cashups) {
                            print_order.lines.push({ text:" "})
                            print_order.lines.push({ value:"line"})
                            print_order.lines.push({
                                align:"center",
                                bold:false, 
                                text:"SHORTAGE(S) / OVERAGE(S)"
                            })

                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"OPENING SHORTAGES: ", 
                                    // last:" ("+this.MBS.actions.money(total_cashups.total_open_shortages*-1)+")",  
                                    last:" "+this.MBS.actions.money(total_cashups.total_open_shortages*-1),  
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"OPENING OVERAGES: ", 
                                    last:" "+this.MBS.actions.money(total_cashups.total_open_overages),  
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"CLOSING SHORTAGES: ", 
                                    // last:" ("+this.MBS.actions.money(total_cashups.total_close_shortages*-1)+")",  
                                    last:" "+this.MBS.actions.money(total_cashups.total_close_shortages*-1),  
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"CLOSING OVERAGES: ", 
                                    last:" "+total_cashups.total_close_overages_,  
                                    size:paper_size
                                }) 
                            })  
                            
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"TOTAL: ", 
                                    last:" "+(
                                        total_cashups.total_is_shortage?"("+
                                        this.MBS.actions.money(total_cashups.total_shortage*-1)+")":
                                        this.MBS.actions.money(total_cashups.total_shortage)
                                    ),  
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({ text:" "})
                        }

                        //CASHUPS SUMMARY
                        if (total_cashups) {
                            print_order.lines.push({ text:" "})
                            print_order.lines.push({ value:"line"})
                            print_order.lines.push({
                                align:"center",
                                bold:false, 
                                text:"CASHUPS(S) SUMMARY"
                            }) 
                            let total_pricing_tiers = total_cashups.total_pricing_tiers
                            if (total_pricing_tiers) {
                                total_pricing_tiers.forEach(pricing_tier => {
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:this.MBS.actions.EPSON_1LINE({
                                            first:this.MBS.actions.TEXT_UP(pricing_tier.name)+":", 
                                            last:this.MBS.actions.money(pricing_tier.sum_amount),  
                                            size:paper_size
                                        }) 
                                    })  
                                }); 
                            }
                             
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"CASH SALES:", 
                                    last:this.MBS.actions.money(total_cashups.sales_cash_payments),  
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"OTHER SALES:", 
                                    last:this.MBS.actions.money(total_cashups.sales_total_other_option_payments),  
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"ON ACCOUNT:", 
                                    last:this.MBS.actions.money(total_cashups.sales_on_account),  
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"CASH INVOICE:", 
                                    last:this.MBS.actions.money(total_cashups.invoices_cash_payments),  
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"OTHER INVOICE:", 
                                    last:this.MBS.actions.money(total_cashups.invoices_total_other_option_payments),  
                                    size:paper_size
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:this.MBS.actions.EPSON_1LINE({
                                    first:"TOTAL SALES:", 
                                    last:this.MBS.actions.money(total_cashups.day_shift_total_sales),  
                                    size:paper_size
                                }) 
                            })  
                        }
                        
                        //TOTAL SUMMARY
                        if (report_type != "X-READ" && total_cashups) { 
                            print_order.lines.push({ text:" "}) 
                            print_order.lines.push({ value:"line"})
                            let closing_cash = this.toNumber(total_cashups.closing_cashes) 
                                + this.toNumber(total_cashups.total_open_shortage)
                                - this.toNumber(total_cashups.opening_cashes)
                            
                            if (isRunning) {
                                print_order.lines.push({
                                    align:"left",
                                    bold:true,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"CURRENT EXPECTED:", 
                                        last:""+total_cashups.total_cash_,  
                                        size:paper_size
                                    }) 
                                }) 
                                print_order.lines.push({
                                    align:"left",
                                    bold:true,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"CURRENT TOTAL:", 
                                        last:" "+this.MBS.actions.money(closing_cash+used_floating_cash),  
                                        size:paper_size
                                    }) 
                                }) 
                                
                                print_order.lines.push({
                                    align:"left",
                                    bold:true,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"CLOSING:", 
                                        last:" [Waiting...]",  
                                        size:paper_size
                                    }) 
                                }) 
                            }else{
                                print_order.lines.push({
                                    align:"left",
                                    bold:true,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"EXPECTED:", 
                                        last:""+total_cashups.total_cash_,  
                                        size:paper_size
                                    }) 
                                }) 
                                
                                print_order.lines.push({
                                    align:"left",
                                    bold:true,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"TOTAL CASH:", 
                                        last:""+this.MBS.actions.money(closing_cash+used_floating_cash),   
                                        size:paper_size
                                    }) 
                                }) 
                                print_order.lines.push({
                                    align:"left",
                                    bold:true,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"USED TILL(S) FLOAT:", 
                                        last:""+this.MBS.actions.money(used_floating_cash),  
                                        size:paper_size
                                    }) 
                                })
                                
                                print_order.lines.push({
                                    align:"left",
                                    bold:true,
                                    value:"",
                                    text:this.MBS.actions.EPSON_1LINE({
                                        first:"CLOSING:", 
                                        last:" "+this.MBS.actions.money(closing_cash),  
                                        size:paper_size
                                    }) 
                                })     
                            }
                        } 

                        //POWERED BY
                        if (true) {
                            print_order.lines.push({ text:" "}) 
                            print_order.lines.push({ text:" "}) 
                            print_order.lines.push({ value:"line"})
                            print_order.lines.push({
                                align:"center",
                                bold:true, 
                                text:'POWERED BY KIPCOUNT'
                            }) 
                            print_order.lines.push({
                                align:"center",
                                bold:true, 
                                text:'+265993259202'
                            }) 
                        } 
                        // return print_order 
                        resolve({
                            success:true,
                            data:print_order,
                        }) 
                    } catch (error) {
                        this.show = false
                        this.inputLoading = false 
                        response = {
                            ...response,
                            error:error,
                            success:false
                        } 
                        this.MBS.actions.error(response)
                        reject(response) 
                    }  
                })
            }, 
            TOTAL_AMOUNT(items){
                try {  
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });  
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TOTAL_AMOUNT',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },   
            SHORTAGE(cashup){
                try { 
                    if(!cashup){return 0} 
                    if (!cashup.closed) {
                        return 0
                    }
                    let sales_cash_payments = this.toNumber(cashup.sales_cash_payments)
                    let sales_on_account = this.toNumber(cashup.sales_on_account)
                    let invoices_cash_payments = this.toNumber(cashup.invoices_cash_payments)
                    let closing_cash = this.toNumber(cashup.closing_cash)
                    let opening_cash = this.toNumber(cashup.opening_cash)
                    let total_cash = sales_cash_payments+opening_cash+invoices_cash_payments
                    let def =  closing_cash-total_cash
 
                    if (total_cash>closing_cash) {
                       return def*-1
                    }
                    return 0
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SHORTAGE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            OVERAGE(cashup){
                try { 
                    if(!cashup){return 0}
                    if (!cashup.closed) {
                        return 0
                    }
                    let sales_cash_payments = this.toNumber(cashup.sales_cash_payments)
                    let sales_on_account = this.toNumber(cashup.sales_on_account)
                    let invoices_cash_payments = this.toNumber(cashup.invoices_cash_payments)
                    let closing_cash = this.toNumber(cashup.closing_cash)
                    let opening_cash = this.toNumber(cashup.opening_cash)
                    let total_cash = sales_cash_payments+opening_cash+invoices_cash_payments
                    let def =  closing_cash-total_cash
                    if (total_cash<closing_cash) {
                       return def 
                    }
                    return 0
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OVERAGE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = this.toNumber(item.sold_price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
            ORDER_ITEMS(orders){  
                try { 
                    if(!orders){return null} 
                    let items = []
                    orders.forEach(element => {
                        let joined_items = element.joined_items
                        let payment_option_code = element.payment_option_code
                        if (joined_items) {
                            joined_items.forEach(item => {
                                items.push({
                                    ...item,
                                    payment_option_code:payment_option_code,
                                    account_type:element.account_type
                                }) 
                            }); 
                        }
                    }); 
                    return items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ORDER_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RETAIL_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("retail price")
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'RETAIL_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            WHOLESALE_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("wholesale price")
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'WHOLESALE_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CASH_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "cash"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CASH_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            OTHER_OPTIONS_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "payment_option"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OTHER_OPTIONS_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_ACCOUNT_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "on_account"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_ACCOUNT_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            TOTAL_SOLD(items){
                try {  
                    let total = 0
                    if (items) {
                        if(!items){return null}
                        items.forEach(element => {
                            let sold_price = this.toNumber(element.sold_price)
                            let quantity = this.toNumber(element.quantity)
                            let discount = this.toNumber(element.discount)
                            total = total+((sold_price-discount)*quantity)
                        }); 
                    }
                    return total
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TOTAL_SOLD',
                        page:PAGE_NAME, 
                    })
                }  
            },  
        },
        watch:{
            mbs_dialog(value){ 
            },  
            DayShift(value){ 
                this.EPSON_DAY_SHIFT_REQUEST({
                    report_type:this.report_type,
                    day_shift:this.DayShift,
                }) 
            },   
        } 
    }
</script>
